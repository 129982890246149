<!--数据-->
<template>
  <div class="container data-box">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-left">
          <div class="img">
            <img src="../../assets/img/img-n15.png" alt=""/>
          </div>
          固体废物特征与资源化技术数据查询
        </h1>
      </div>
      <div class="col-md-12">
        <div class="tab-part">
          <div :class="{ active: activeIndex == 1 }" @click="tabClick(1)">
            固体废物特征
          </div>
          <div :class="{ active: activeIndex == 2 }" @click="tabClick(2)">
            固体废物资源化技术
          </div>
        </div>
      </div>
    </div>

    <div class="classify-cont">
      <div class="row">
        <div class="col-md-4">
          <div class="part-c">
            <div class="tit">固废来源</div>
            <div class="t1">
              <img src="../../assets/img/img-n22.png" alt=""/>
              <input placeholder="输入搜索内容" v-model="content1"/>
            </div>
            <div class="t2">
              <el-checkbox-group
                  v-model="checkedCities1"
                  @change="handleCheckedCitiesChange1"
              >
                <el-checkbox
                    v-for="(city, index) in options.wasteSource"
                    :label="city.label"
                    :key="index"
                >{{ city.label }}
                </el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="t3">
              <div class="active" @click="handleCheckAllChange(1)">
                全部选中
              </div>
              <div @click="eliminate(1)">清除选择</div>
            </div>
            <div class="t4">
              <el-tag
                  :key="tag"
                  v-for="tag in checkedCities1"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 1)"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="part-c">
            <div class="tit">固废大类</div>
            <div class="t1">
              <img src="../../assets/img/img-n22.png" alt=""/>
              <input placeholder="输入搜索内容" v-model="content2"/>
            </div>
            <div class="t2">
              <el-checkbox-group
                  v-model="checkedCities2"
                  @change="handleCheckedCitiesChange2"
              >
                <el-checkbox
                    v-for="(city, index) in options.wasteBigType"
                    :label="city.label"
                    :key="index"
                >{{ city.label }}
                </el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="t3">
              <div class="active" @click="handleCheckAllChange(2)">
                全部选中
              </div>
              <div @click="eliminate(2)">清除选择</div>
            </div>
            <div class="t4">
              <el-tag
                  :key="tag"
                  v-for="tag in checkedCities2"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 2)"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="part-c">
            <div class="tit">固废小类</div>
            <div class="t1">
              <img src="../../assets/img/img-n22.png" alt=""/>
              <input placeholder="输入搜索内容" v-model="content3"/>
            </div>
            <div class="t2">
              <el-checkbox-group
                  v-model="checkedCities3"
                  @change="handleCheckedCitiesChange3"
              >
                <el-checkbox
                    v-for="(city, index) in options.wasteSmallType"
                    :label="city.label"
                    :key="index"
                >{{ city.label }}
                </el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="t3">
              <div class="active" @click="handleCheckAllChange(3)">
                全部选中
              </div>
              <div @click="eliminate(3)">清除选择</div>
            </div>
            <div class="t4">
              <el-tag
                  :key="tag"
                  v-for="tag in checkedCities3"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 3)"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="part-c">
            <div class="tit">年份</div>
            <div class="t1">
              <img src="../../assets/img/img-n22.png" alt=""/>
              <input placeholder="输入搜索内容" v-model="content4"/>
            </div>
            <div class="t2">
              <el-checkbox-group
                  v-model="checkedCities4"
                  @change="handleCheckedCitiesChange4"
              >
                <el-checkbox
                    v-for="(city, index) in options.years"
                    :label="city.label"
                    :key="index"
                >{{ city.label }}
                </el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="t3">
              <div class="active" @click="handleCheckAllChange(4)">
                全部选中
              </div>
              <div @click="eliminate(4)">清除选择</div>
            </div>
            <div class="t4">
              <el-tag
                  :key="tag"
                  v-for="tag in checkedCities4"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 4)"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="part-c">
            <div class="tit">区域</div>
            <div class="t1">
              <el-cascader
                  :options="options.area"
                  v-model="searchModel.regions"
                  :props="{
                  multiple: true,
                  checkStrictly: true,
                  emitPath: false,
                }"
                  clearable
                  collapse-tags
              >
              </el-cascader>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="part-c">
            <div class="tit">模糊查询</div>
            <div class="t1">
              <img src="../../assets/img/img-n22.png" alt=""/>
              <input
                  placeholder="输入搜索内容"
                  v-model="searchModel.searchValue"
              />
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="query-but" @click="selectData">
            <img src="../../assets/img/img-n22.png" alt=""/>查询结果
          </div>
        </div>
      </div>
    </div>
    <!-- 固体废物特征 -->
    <el-row v-if="activeIndex == 1">
      <b-card class="col-ms-12 search-data" header="查询结果">
        <el-table :data="table.tableData" style="width: 100%" max-height="500">
          <el-table-column prop="areaName" label="区域" width="200">
          </el-table-column>
          <el-table-column prop="year" label="年份" width="200">
          </el-table-column>
          <el-table-column prop="wasteBigName" label="固废大类" width="250">
          </el-table-column>
          <el-table-column prop="wasteSubName" label="固废小类" width="250">
          </el-table-column>
          <!--<el-table-column prop="color" label="颜色" width="200">
          </el-table-column>
          <el-table-column prop="form" label="形状" width="200">
          </el-table-column>-->
          <el-table-column prop="source" label="固废来源" width="200">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="250">
            <template slot-scope="scope">
              <el-button
                  @click.native.prevent="viewDetail(scope.row.id)"
                  type="text"
                  size="small"
              >
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            layout="total, prev, pager, next"
            :total="table.total"
            :page-count="table.pageCount"
            :page-size="table.pageSizes"
            :current-page="table.currentPage"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </b-card>
    </el-row>

    <!-- 固体废物资源化技术 -->
    <el-row v-if="activeIndex == 2">
      <b-card class="col-ms-12 search-data" header="查询结果">
        <el-table
            :data="table1.tableData"
            style="width: 100%; overflow: auto"
            max-height="500"
        >
          <el-table-column prop="techName" label="技术名称" width="440">
          </el-table-column>
          <el-table-column prop="fillingTime" label="填报时间" width="180">
            <template slot-scope="scope">
              {{ formatDate(scope.row.fillingTime) }}
            </template>
          </el-table-column>
          <!--          <el-table-column fixed prop="areaName" label="区域" width="150">-->
          <!--          </el-table-column>-->
          <el-table-column prop="wasteName" label="固废类型" width="180">
          </el-table-column>
          <el-table-column prop="personLiable" label="提交人" width="150">
          </el-table-column>
          <el-table-column prop="fillingUnit" label="提交单位" width="250">
          </el-table-column>
          <!--<el-table-column prop="unitAddress" label="单位地址" width="250">
          </el-table-column>-->
          <!--<el-table-column prop="indTotal" label="综合影响" width="150">
          </el-table-column>-->
          <el-table-column fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                  @click.native.prevent="viewDetail2(scope.row.id)"
                  type="text"
                  size="small"
              >
                详情
              </el-button>
              <el-button
                  @click.native.prevent="viewChart(scope.row.id)"
                  type="text"
                  size="small"
              >
                雷达图
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            layout="total, prev, pager, next"
            :total="table1.total"
            :page-count="table1.pageCount"
            :page-size="table1.pageSizes"
            :current-page="table1.currentPage"
            @current-change="handleCurrentChange1"
        >
        </el-pagination>
      </b-card>
    </el-row>

    <el-dialog
        title="详情"
        :visible.sync="view.detailVisible"
        width="60%"
        class="detailDialog"
    >
      <i style="color: blue">说明：默认单位（万吨）</i>
      <el-row :gutter="20">
        <el-col :span="6">
          固废名称: <span>{{ this.dialogData.wasteBigName }}</span>
        </el-col>
        <el-col :span="6">
          年份: <span>{{ this.dialogData.year }}</span>
        </el-col>
        <el-col :span="6">
          区域: <span>{{ this.dialogData.areaName }}</span>
        </el-col>
        <!--<el-col :span="6">
          产生量(吨): <span>{{ this.dialogData.wasteGeneration }}</span>
        </el-col>-->
      </el-row>
      <div v-for="i in this.dialogData.propRow">
        <el-row :gutter="20">
          <el-col :span="6" v-for="j in 4" v-if="i !== dialogData.propRow">
            {{ dialogData.props[(4 * (i - 1)) + j - 1].key }}: <span>{{
              dialogData.props[(4 * (i - 1)) + j - 1].value
            }}</span>
            <!--{{i + '-' + j}}-->
          </el-col>
          <el-col :span="6" v-for="j in dialogData.lastCol" v-if="i === dialogData.propRow">
            {{ dialogData.props[(4 * (i - 1)) + j - 1].key }}:
            <span>{{ dialogData.props[(4 * (i - 1)) + j - 1].value }}</span>
            <!--{{i + '-' + j}}-->
          </el-col>
        </el-row>
      </div>
      <!--<el-row :gutter="20">
        <el-col :span="6">
          颜色: <span>{{ this.dialogData.color }}</span>
        </el-col>
        <el-col :span="6">
          形态: <span>{{ this.dialogData.form }}</span>
        </el-col>
        <el-col :span="6">
          粒径（um）: <span>{{ this.dialogData.particleSize }}</span>
        </el-col>
        <el-col :span="6">
          渗透系数（cm/s）:
          <span>{{ this.dialogData.permeabilityCoeffcient }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          二水硫酸钙含量: <span>{{ this.dialogData.csd }}</span>
        </el-col>
        <el-col :span="6">
          放射性: <span>{{ this.dialogData.radioactivity }}</span>
        </el-col>
        <el-col :span="6">
          ph: <span>{{ this.dialogData.ph }}</span>
        </el-col>
        <el-col :span="6">
          二氧化硅: <span>{{ this.dialogData.siliconDioxide }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          氧化铝: <span>{{ this.dialogData.alumina }}</span>
        </el-col>
        <el-col :span="6">
          氧化铁: <span>{{ this.dialogData.ferricOxide }}</span>
        </el-col>
        <el-col :span="6">
          氧化钙:<span> {{ this.dialogData.calciumOxide }}</span>
        </el-col>
        <el-col :span="6">
          三氧化硫: <span>{{ this.dialogData.sulfurTrioxide }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          结晶水: <span>{{ this.dialogData.crystalWaster }}</span>
        </el-col>
        <el-col :span="6">
          氧化镁: <span>{{ this.dialogData.magensiumOxide }}</span>
        </el-col>
      </el-row>-->
      <!--            <el-row :gutter="20">
                      <el-col :span="6">
                          利用量: {{this.dialogData.wasteRecovery}}
                      </el-col>
                      <el-col :span="6">
                          生活垃圾处置量: {{this.dialogData.wasteDisposal}}
                      </el-col>
                      <el-col :span="6">
                          贮存量: {{this.dialogData.wasteStorage}}
                      </el-col>
                      <el-col :span="6">
                          物理组成: {{this.dialogData.composition}}
                      </el-col>
                  </el-row>
                  <el-row :gutter="20">
                      <el-col :span="6">
                          密度: {{this.dialogData.wasteDensity}}
                      </el-col>
                      <el-col :span="6">
                          含水率: {{this.dialogData.moistureContent}}
                      </el-col>
                      <el-col :span="6">
                          关键物质组分: {{this.dialogData.keyElement}}
                      </el-col>
                      <el-col :span="6">
                          毒害物质组分: {{this.dialogData.toxicElement}}
                      </el-col>
                  </el-row>
                  <el-row :gutter="20">
                      <el-col :span="6">
                          灰分: {{this.dialogData.ashContent}}
                      </el-col>
                      <el-col :span="6">
                          高位热值: {{this.dialogData.highHeatValue}}
                      </el-col>
                      <el-col :span="6">
                          地位热值: {{this.dialogData.lowHeatValue}}
                      </el-col>
                      <el-col :span="6">
                          有机碳: {{this.dialogData.organicCarbon}}
                      </el-col>
                  </el-row>
                  <el-row :gutter="20">
                      <el-col :span="6">
                          挥发性物质: {{this.dialogData.volatileMatter}}
                      </el-col>
                      <el-col :span="6">
                          生物降解铀组分: {{this.dialogData.biodegradableFraction}}
                      </el-col>
                      <el-col :span="6">
                          机械化: {{this.dialogData.mechanization}}
                      </el-col>
                  </el-row>-->
      <span slot="footer" class="dialog-footer">
        <!--        <el-button @click="view.dialogVisible = false">关 闭</el-button>-->
      </span>
    </el-dialog>

    <b-card class="col-ms-12 use-data" header="使用说明">{{this.instructions}}</b-card>

    <el-dialog title="技术雷达图" :visible.sync="view.chartVisible" width="60%" @opened="initChart">
      <div ref="charts2" class="echarts-content"></div>
    </el-dialog>

    <el-dialog title="详情" :visible.sync="view.detailVisible2" width="60%" class="detailDialog">
      <el-row :gutter="20">
        <el-col :span="6">
          技术名称: <span>{{ this.dialogData.techName }}</span>
        </el-col>
        <el-col :span="6">
          信息采集负责人: <span>{{ this.dialogData.personLiable }}</span>
        </el-col>
        <el-col :span="6">
          联系方式: <span>{{ this.dialogData.applicationUnit }}</span>
        </el-col>
        <el-col :span="6">
          填报单位: <span>{{ this.dialogData.fillingUnit }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          技术应用单位地址: <span>{{ this.dialogData.unitAddress }}</span>
        </el-col>
        <el-col :span="6">
          填报时间: <span>{{ this.dialogData.fillingTime | formatDate }}</span>
        </el-col>
        <el-col :span="6">
          项目投产时间:<span> {{ this.dialogData.productionTime | formatDate }}</span>
        </el-col>
        <el-col :span="6">
          数据来源: <span> {{ this.dialogData.source }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          固废大类: <span> {{ this.dialogData.wasteType }}</span>
        </el-col>
        <el-col :span="6">
          固废小类: <span> {{ this.dialogData.wasteSubtype }}</span>
        </el-col>
        <el-col :span="6">
          资源化产品大类: <span>{{ this.dialogData.resourceType }}</span>
        </el-col>
        <el-col :span="6">
          资源化产品小类:<span> {{ this.dialogData.resourceSubtype }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          技术就绪度自评: <span>{{ this.dialogData.techReadiness }}</span>
        </el-col>
        <el-col :span="6">
          年固废处理规模(吨): <span>{{ this.dialogData.treatmentScale }}</span>
        </el-col>
        <el-col :span="6">
          年处理固废总量(吨): <span>{{ this.dialogData.treatmentTotal }}</span>
        </el-col>
        <el-col :span="6">
          新鲜水（自来水）消耗量(立方米/年): <span>{{ this.dialogData.tapWasterConsumption }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          项目占地面积(平方米): <span>{{ this.dialogData.areaCovered }}</span>
        </el-col>
        <el-col :span="6">
          电消耗量(千瓦时/年):<span> {{ this.dialogData.electricityConsumption }}</span>
        </el-col>
        <el-col :span="6">
          原煤消耗量(吨/年): <span>{{ this.dialogData.coalConsumption }}</span>
        </el-col>
        <el-col :span="6">
          天然气消耗量(m3):<span> {{ this.dialogData.naturalGasConsumption }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          蒸汽消耗量(立方米/年):<span> {{ this.dialogData.steamConsumption }}</span>
        </el-col>
        <el-col :span="6">
          柴油消耗量(吨/年): <span> {{ this.dialogData.dieselConsumption }}</span>
        </el-col>
        <el-col :span="6">
          汽油消耗(吨/年): <span> {{ this.dialogData.oilConsumption }}</span>
        </el-col>
        <el-col :span="6">
          进入到资源化生产过程中的固废量(吨/年): <span>{{ this.dialogData.resourceWaste }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          甲烷产生量(立方米/年): <span> {{ this.dialogData.copperProduction }}</span>
        </el-col>
        <el-col :span="6">
          循环水利用量(吨): <span>{{ this.dialogData.circulatingWaterConsumption }}</span>
        </el-col>
        <el-col :span="6">
          余热利用量(W): <span>{{ this.dialogData.wasteHeatRecovery }}</span>
        </el-col>
        <el-col :span="6">
          废气年排放总量(立方米/年): <span>{{ this.dialogData.gasTotal }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          二氧化硫年排放总量(吨/年): <span>{{ this.dialogData.so2Total }}</span>
        </el-col>
        <el-col :span="6">
          氮氧化物年排放总量(吨/年): <span>{{ this.dialogData.noxTotal }}</span>
        </el-col>
        <el-col :span="6">
          废水年排放总量(立方米/年): <span>{{ this.dialogData.sewageTotal }}</span>
        </el-col>
        <el-col :span="6">
          化学需氧量（COD)年排放总量(吨/年): <span>{{ this.dialogData.codTotal }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          化学需氧量（BOD)年排放总量(吨/年): <span>{{ this.dialogData.bodTotal }}</span>
        </el-col>
        <el-col :span="6">
          氨氮（NH3-N)年排放总量(吨/年): <span>{{ this.dialogData.nh3nTotal }}</span>
        </el-col>
        <el-col :span="6">
          总磷（P)年排放总量(吨/年): <span>{{ this.dialogData.phosphorusTotal }}</span>
        </el-col>
        <el-col :span="6">
          固废（废渣）年排放总量(吨/年): <span>{{ this.dialogData.wasteDischarge }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          总投资建设费用(万元): <span>{{ this.dialogData.constructionCost }}</span>
        </el-col>
        <el-col :span="6">
          年运行成本(万元/年): <span>{{ this.dialogData.operatingCost }}</span>
        </el-col>
        <el-col :span="6">
          销售收入(万元/年): <span>{{ this.dialogData.salesRevenue }}</span>
        </el-col>
        <el-col :span="6">
          净利润(万元/年): <span>{{ this.dialogData.netProfit }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          净现值(万元):<span> {{ this.dialogData.netPresentValue }}</span>
        </el-col>
        <el-col :span="6">
          专利价值(万元):<span> {{ this.dialogData.patentValue }}</span>
        </el-col>
        <el-col :span="6">
          提供就业岗位数(个):<span> {{ this.dialogData.offerJobTotal }}</span>
        </el-col>
        <el-col :span="6">
          循环经济示范性(是/否): <span>{{ this.dialogData.circularEconomy |formatBool }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          职工可能暴露在腐蚀/有毒化学品环境下:<span> {{ this.dialogData.inToxicCorrosion |formatBool }}</span>
        </el-col>
        <el-col :span="6">
          职工可能暴露在泼溅条件下: <span>{{ this.dialogData.inHighSpeed |formatBool }}</span>
        </el-col>
        <el-col :span="6">
          职工可能暴露在高温表面下: <span>{{ this.dialogData.inHighTemperature |formatBool }}</span>
        </el-col>
        <el-col :span="6">
          职工可能暴露在高速部件下:<span> {{ this.dialogData.inSplash |formatBool }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          职工可能暴露在高压电下: <span>{{ this.dialogData.inHighVoltage |formatBool }}</span>
        </el-col>
        <el-col :span="6">
          职工可能暴露在高分贝噪声下: <span>{{ this.dialogData.inHighDecibel |formatBool }}</span>
        </el-col>
        <el-col :span="6">
          职工可能暴露在难闻气味下:<span> {{ this.dialogData.inOdorousGas | formatBool }}</span>
        </el-col>
        <el-col :span="6">
          职工可能暴露在充满灰尘工作条件下: <span>{{ this.dialogData.inDust | formatBool }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          公众对技术实施的态度: <span>{{ this.dialogData.publicRecognition | formatBool }}</span>
        </el-col>
        <el-col :span="6">
          职工平均工资(万元/年): <span>{{ this.dialogData.avgWage }}</span>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
                <!--        <el-button @click="view.dialogVisible = false">关 闭</el-button>-->
            </span>
    </el-dialog>
  </div>
</template>
<script>
import ui from "@/libs/ui";
import core from "@/libs/core";
import {
  getWasteSourceSelect,
  getWasteBigTypeSelect,
  getWasteSmallTypeSelect,
  getAreaSelect,
  getYearSelect, getTechYearSelect,
} from "@/api/common";
import {
  queryFeatureTableData,
  queryFeatureDetail,
  queryEvaluateTable,
  queryTechChart,
  queryTechDetail
} from "@/api/waste";

export default {
  name: "Data",
  props: {
    msg: String,
  },
  data() {
    return {
      activeIndex: 1,
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      checkedCities1: [],
      checkedCities2: [],
      checkedCities3: [],
      checkedCities4: [],
      checkedCities: [],
      cities: ["上海", "北京", "广州", "深圳"],
      dynamicTags: ["上海", "北京", "广州", "深圳"],
      view: {
        detailVisible: false,
        chartVisible: false,
        detailVisible2: false,
        charts2: {}
      },
      dialogData: {},
      searchModel: {
        searchValue: "",
        sources: [],
        wasteBigTypes: [],
        wasteSmallTypes: [],
        regions: [],
        years: [],
      },
      options: {
        wasteSourceFil: [],
        wasteSource: [],
        wasteBigTypeFil: [],
        wasteBigType: [],
        wasteSmallTypeFil: [],
        wasteSmallType: [],
        area: [],
        yearsFil: [],
        years: [],
      },
      table: {
        tableData: [],
        total: 0,
        currentPage: 1,
        pageCount: 0,
        pageSizes: 20,
      },
      table1: {
        tableData: [],
        total: 0,
        currentPage: 1,
        pageCount: 0,
        pageSizes: 20,
      },
      mapOptions: {
        title: {
          text: '技术评价雷达图'
        },
        tooltip: {},
        legend: {
          data: ['技术']
        },
        radar: {
          // shape: 'circle',
          name: {
            textStyle: {
              color: '#fff',
              backgroundColor: '#999',
              borderRadius: 3,
              padding: [3, 5]
            }
          },
          indicator: [
            {name: '资源影响（R）', max: 50},
            {name: '环境影响（En）', max: 50},
            {name: '经济影响（Ec）', max: 50},
            {name: '社会影响（S）', max: 50},
          ]
        },
        series: [{
          name: '技术',
          type: 'radar',
          // areaStyle: {normal: {}},
          data: [
            {
              label: {
                show: true,
                formatter: function (params) {
                  return params.value;
                }
              },
              value: [90, 89, 95, 100],
              name: '11'
            }
          ]
        }]
      },
      instructions: ''
    };
  },
  watch: {
    content1(val) {
      this.options.wasteSource = this.options.wasteSourceFil.filter((p) => {
        return p.label.indexOf(val) !== -1;
      });
    },
    content2(val) {
      this.options.wasteBigType = this.options.wasteBigTypeFil.filter((p) => {
        return p.label.indexOf(val) !== -1;
      });
    },
    content3(val) {
      this.options.wasteSmallType = this.options.wasteSmallTypeFil.filter(
          (p) => {
            return p.label.indexOf(val) !== -1;
          }
      );
    },
    content4(val) {
      this.options.years = this.options.yearsFil.filter((p) => {
        return p.label.indexOf(val) !== -1;
      });
    },
  },
  mounted() {
    this.init();
  },
  filters: {
    formatBool(val) {
      let rtl
      if (val === 0) {
        rtl = '否'
      } else {
        rtl = '是'
      }
      return rtl
    },
    formatDate(val) {
      if (val) {
        val = core.formatDate(val, 'YYYY-mm-dd')
      }
      return val
    }
  },
  methods: {
    initChart() {
      this.view.charts2 = this.$echarts.init(this.$refs.charts2);
      this.view.charts2.setOption(this.mapOptions);
    },
    tabClick(i) {
      this.activeIndex = i;
      if (i == 1) {
        this.queryTableData();
      }
      if (i == 2) {
        this.queryYearSelect()
        this.queryTableData1();
      }

      this.queryInstructions()
    },
    handleData(data1, data2) {
      let labelArr = [];
      data1.forEach((item) => {
        data2.forEach((item2) => {
          if (item.label == item2) {
            labelArr.push(item.value);
          }
        });
      });
      return labelArr;
    },
    handleCheckedCitiesChange1(val) {
      this.searchModel.sources.length = 0
      val.forEach(x => {
        this.searchModel.sources.push(x)
      })
      this.queryWasteBigSelect();
    },
    handleCheckedCitiesChange2(val) {
      let labelArr = [];
      this.options.wasteBigType.forEach((item) => {
        val.forEach((item2) => {
          if (item.label == item2) {
            labelArr.push(item.value);
          }
        });
      });
      this.searchModel.wasteBigTypes = labelArr;
      this.queryWasteSmallSelect();
    },
    handleCheckedCitiesChange3(val) {
      let labelArr = [];
      this.options.wasteSmallType.forEach((item) => {
        val.forEach((item2) => {
          if (item.label == item2) {
            labelArr.push(item.value);
          }
        });
      });
      this.searchModel.wasteSmallTypes = labelArr;
    },
    //单选
    handleCheckedCitiesChange4(val) {
      let labelArr = [];
      this.options.years.forEach((item) => {
        val.forEach((item2) => {
          if (item.label == item2) {
            labelArr.push(item.value);
          }
        });
      });
      this.searchModel.years = labelArr;
    },
    //全选
    handleCheckAllChange(n) {
      if (n == 1) {
        this.checkedCities1 = [];
        this.options.wasteSource.forEach((item) => {
          this.checkedCities1.push(item.label);
          this.searchModel.sources.push(item.label)
        });
        this.queryWasteBigSelect();
      }
      if (n == 2) {
        this.checkedCities2 = [];
        this.options.wasteBigType.forEach((item) => {
          this.checkedCities2.push(item.label);
        });

        this.searchModel.wasteBigTypes = this.handleData(
            this.options.wasteBigType,
            this.checkedCities2
        );
        this.queryWasteSmallSelect();
      }
      if (n == 3) {
        this.checkedCities3 = [];
        this.options.wasteSmallType.forEach((item) => {
          this.checkedCities3.push(item.label);
        });

        this.searchModel.wasteSmallTypes = this.handleData(
            this.options.wasteSmallType,
            this.checkedCities3
        )
      }
      if (n == 4) {
        this.checkedCities4 = [];
        this.options.years.forEach((item) => {
          this.checkedCities4.push(item.label);
          this.searchModel.years = this.checkedCities4;
        });
      }
    },
    //清除
    eliminate(n) {
      if (n == 1) {
        this.checkedCities1 = [];
        this.searchModel.sources = [];
        this.queryWasteBigSelect()
      }
      if (n == 2) {
        this.checkedCities2 = [];
        this.searchModel.wasteBigTypes = [];
        this.queryWasteSmallSelect()
      }
      if (n == 3) {
        this.checkedCities3 = [];
        this.searchModel.wasteSmallTypes = [];
      }
      if (n == 4) {
        this.checkedCities4 = [];
        this.searchModel.years = [];
      }
    },
    handleClose(tag, n) {
      if (n == 1) {
        this.checkedCities1.splice(this.checkedCities1.indexOf(tag), 1);
        this.searchModel.sources.splice(
            this.searchModel.sources.indexOf(tag),
            1
        );
        this.queryWasteBigSelect();
      }
      if (n == 2) {
        this.checkedCities2.splice(this.checkedCities2.indexOf(tag), 1);
        this.searchModel.wasteBigTypes.splice(
            this.searchModel.wasteBigTypes.indexOf(tag),
            1
        );
        this.queryWasteSmallSelect()
      }
      if (n == 3) {
        this.checkedCities3.splice(this.checkedCities3.indexOf(tag), 1);
        this.searchModel.wasteSmallTypes.splice(
            this.searchModel.wasteSmallTypes.indexOf(tag),
            1
        );
      }
      if (n == 4) {
        this.checkedCities4.splice(this.checkedCities4.indexOf(tag), 1);
        this.searchModel.years.splice(
            this.searchModel.years.indexOf(tag),
            1
        );
      }
    },
    init() {
      this.queryWasteSourceSelect();
      this.queryAreaSelect();
      this.queryYearSelect();
      this.selectData();
      this.queryInstructions()
    },
    queryInstructions(){
      let item = window.localStorage.getItem("instructions")

      if(this.activeIndex == 1){
        this.instructions = JSON.parse(item)['data_search_feature']
      }else{
        this.instructions = JSON.parse(item)['data_search_tech']
      }
    },
    queryAreaSelect() {
      getAreaSelect().then((data) => {
        this.options.area = data;
      });
    },
    queryYearSelect() {
      if (this.activeIndex == 1) {
        getYearSelect().then((data) => {
          this.options.years = data;
          this.options.yearsFil = data;
        });
      } else {
        getTechYearSelect().then((data) => {
          this.options.years = data;
          this.options.yearsFil = data;
        });
      }
    },
    queryWasteSourceSelect() {
      getWasteSourceSelect().then((data) => {
        this.options.wasteSource = data;
        this.options.wasteSourceFil = data;
      });
    },
    queryWasteBigSelect() {
      if (this.searchModel.sources.length === 0) {
        this.options.wasteBigType.length = 0
        return
      }
      getWasteBigTypeSelect(this.searchModel.sources).then((data) => {
        this.options.wasteBigType = data;
        this.options.wasteBigTypeFil = data;
      });
    },
    queryWasteSmallSelect() {
      if (this.searchModel.wasteBigTypes.length === 0) {
        this.options.wasteSmallType.length = 0
        return
      }
      getWasteSmallTypeSelect(this.searchModel.wasteBigTypes).then((data) => {
        this.options.wasteSmallType = data;
        this.options.wasteSmallTypeFil = data;
      });
    },
    queryTableData(searchParams, page) {
      if (!searchParams) {
        searchParams = this.searchModel;
      }
      queryFeatureTableData(searchParams, page).then((data) => {
        this.table.tableData = data.records;
        this.table.total = data.total;
        this.table.currentPage = data.current;
        this.table.pageSizes = data.size;
        this.table.pageCount = data.pages;
      });
    },
    queryTableData1(searchParams, page) {
      if (!searchParams) {
        searchParams = this.searchModel;
      }
      queryEvaluateTable(searchParams, page).then((data) => {
        this.table1.tableData = data.records;
        this.table1.total = data.total;
        this.table1.currentPage = data.current;
        this.table1.pageSizes = data.size;
        this.table1.pageCount = data.pages;
      });
    },
    handleCurrentChange1(val) {
      const page = {
        pageNo: val,
        pageSize: this.table.pageSizes,
      };
      this.queryTableData1(this.searchModel, page);
    },
    selectData() {
      if (this.activeIndex == 1) {
        this.queryTableData();
      }
      if (this.activeIndex == 2) {
        this.queryTableData1();
      }
    },
    handleCurrentChange(val) {
      const page = {
        pageNo: val,
        pageSize: this.table.pageSizes,
      };
      this.queryTableData(this.searchModel, page);
    },
    viewDetail(id) {
      // debugger
      if (id === null || id === undefined || id === "") {
        return;
      }
      queryFeatureDetail(id).then((data) => {
        let jsonObj = JSON.parse(data.json)
        this.dialogData = data;

        let props = []
        for (let key in jsonObj) {
          props.push({key, value: jsonObj[key]})
        }
        this.dialogData.props = props
        this.dialogData.propRow = Math.ceil(props.length / 4)
        if (props.length > 0 && props.length % 4 === 0) {
          this.dialogData.lastCol = props.length % 4 + 4
        } else {
          this.dialogData.lastCol = props.length % 4
        }
        // debugger
        this.view.detailVisible = true;
      });
    },
    viewDetail2(id) {
      if (id === null || id === undefined || id === '') {
        return
      }
      queryTechDetail(id).then(data => {
        this.dialogData = data
        this.view.detailVisible2 = true
      })
    },
    toSearch() {
      // eslint-disable-next-line no-debugger
      // debugger
      if (this.keyword === "") {
        ui.toast(this, ui.variant.primary, "请输入搜索内容");
        return;
      }
      if (this.$store.getters.token === "") {
        ui.toast(this, ui.variant.primary, "请先登录账号");
        return;
      }
      this.$router.push("/search/" + this.keyword);
    },
    viewChart(id) {
      // debugger
      if (id === null || id === undefined || id === '') {
        return
      }
      let self = this
      self.view.chartVisible = true
      queryTechChart(id).then(data => {
        self.mapOptions.legend.data[0] = data.name
        self.mapOptions.series[0].name = data.name
        self.mapOptions.series[0].data[0].name = data.name
        self.mapOptions.series[0].data[0].value = data.data

        self.initChart()
      })
    },
    formatDate(val) {
      if (val) {
        val = core.formatDate(val, 'YYYY-mm-dd')
      }
      return val
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "../../assets/less/data.less";

.echarts-content {
  height: 600px;
  width: 800px;
  margin-left: 8%;
}
</style>
